var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "reviewContent",
      attrs: { fullscreen: _vm.isFullscreen, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            position: "relative",
            "margin-right": "30px",
            display: "flex",
            "justify-content": "space-between",
            cursor: "pointer",
          },
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _c("p", [_vm._v(_vm._s(_vm.title))]),
          _c("Icon", {
            attrs: { type: "md-expand", size: "24", color: "#ccc" },
            on: { click: _vm.changeFull },
          }),
        ],
        1
      ),
      _vm.pdfUrl && _vm.pdfUrl.trim().length > 0
        ? _c("iframe", {
            staticStyle: {
              width: "100%",
              height: "100%",
              "min-height": "65vh",
            },
            attrs: { src: _vm.pdfUrl },
          })
        : _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                height: "100%",
                "min-height": "65vh",
              },
            },
            [
              _c("no-data", {
                staticStyle: {
                  height: "100%",
                  "min-height": "65vh",
                  overflow: "hidden",
                  display: "flex",
                  "flex-direction": "row",
                  "justify-content": "center",
                },
                attrs: { width: "150" },
              }),
            ],
            1
          ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            { attrs: { type: "text" }, on: { click: _vm.closeLoading } },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
      _c(
        "Spin",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { fix: "" },
        },
        [
          _c("div", { staticClass: "demo-spin-icon-load" }, [
            _c("i", { staticClass: "iconfont icon-loader--line" }),
          ]),
          _c("div", { staticStyle: { "font-size": "16px" } }, [
            _vm._v("Loading..."),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }